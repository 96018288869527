"use client";

import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import NextAppDirEmotionCacheProvider from "./EmotionCache";
import { ThemeProvider } from "@mui/material/styles";
import { Box, GlobalStyles } from "@mui/material";
import { getTheme } from "@/themes/presets";

export default function ThemeRegistry({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = getTheme();
  const backgroundImage = theme.backgroundImage?.body;
  const backgroundSize = theme.backgroundImage?.bodySize || "contain";
  const backgroundSizeMobile = theme.backgroundImage?.bodySizeMobile || "contain";
  const backgroundAttachment = theme.backgroundImage?.backgroundAttachment;
  const backgroundPosition = theme.backgroundImage?.backgroundPosition;
  const bodyOpacity = theme.backgroundImage?.bodyOpacity;

  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <GlobalStyles
        styles={{
          "*": {
            msOverflowStyle: "none",
            // scrollbarWidth: "thin",
          },
          '&::-webkit-scrollbar': {
            width: '0.1em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '0.25em', 
          },
        }}
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {backgroundImage && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: -1,
              overflow: "hidden",
            }}
          >
            <Box
              component="img"
              src={backgroundImage}
              alt="background"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: { xs: backgroundSizeMobile, md: backgroundSize },
                objectPosition: { xs: backgroundPosition, md: "center" },
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            />
            {bodyOpacity && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: `rgba(0, 0, 0, ${bodyOpacity})`,
                  zIndex: -1,
                }}
              />
            )}
          </Box>
        )}
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}

